import React, { useContext, useEffect, useState } from 'react';

import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/match/list.module.scss';
import * as statstyle from 'styles/components/player/page/statistics.module.scss';
import { MATCH_STATUS } from 'data/constants';
import useSanityConfigGlobal from 'data/queries/useSanityConfigGlobal';
import useSeasons from 'data/queries/useSeasons';
import CustomLink from 'components/utils/CustomLink';
import Calendar from 'components/match/Calendar';
import MatchItem from 'components/match/Item';
import ResultsTable from 'components/match/ResultsTable';
import useHammarbyClub from 'data/queries/useHammarbyClub';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import Banner from 'components/misc/Banner';

const MatchList = ({ matches, showResults: InitialShowResults }) => {
    const { lang } = useContext(LangContext);
    const club = useHammarbyClub();
    const sanityConfigGlobal = useSanityConfigGlobal();
    const matchlistAds = sanityConfigGlobal?.matchlistAdsSidebar;
    const matchlistBanner = sanityConfigGlobal?.matchlistBanner;
    const allSeasons = useSeasons();
    const [seasons, setSeasons] = useState([]);
    const [currentSeason, setCurrentSeason] = useState('');
    const [filteredTeam, setFilteredTeam] = useState('');
    // Show results according to "showResults" passed from router
    const [showResults] = useState(InitialShowResults);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setFilteredTeam(urlParams.get('selectedTeam') !== 'null' ? urlParams.get('selectedTeam') : null);
    }, [club, lang.translationKey]);

    useEffect(() => {
        const seasonList = [...new Set(allSeasons.map(item => parseInt(item.title, 10)))];
        if( currentSeason === '' ) setCurrentSeason(Math.max(...seasonList));
        setSeasons(seasonList);
    }, [allSeasons, filteredTeam, currentSeason]);

    // const TOP_FILTERS_LIMIT = 8;
    const TOP_LIST_LIMIT = 0;
    // when the team is filter is null we take the 2 first teams only (men and women)
    const results = () =>
        matches
            .filter((match) => match.season.title === currentSeason.toString())
            .filter((match) => match.matchStatus === MATCH_STATUS.FINISHED)
            .filter((item) => !filteredTeam
                ? item.team.id === club.teams[0].id || item.team.id === club.teams[1].id || item.teamExternal.id === club.teams[0].id || item.teamExternal.id === club.teams[1].id
                : item.team.id === filteredTeam  || item.teamExternal.id === filteredTeam,
            )
            .sort((a, b) => new Date(b.startEventDate) - new Date(a.startEventDate));

    const upcomingMatches = () =>
        matches
            .filter((match) => match.matchStatus !== MATCH_STATUS.FINISHED)
            .filter((item) => !filteredTeam
                ? item.team.id === club.teams[0].id || item.team.id === club.teams[1].id || item.teamExternal.id === club.teams[0].id || item.teamExternal.id === club.teams[1].id
                : item.team.id === filteredTeam  || item.teamExternal.id === filteredTeam,
            )
            .sort((a, b) => new Date(a.startEventDate) - new Date(b.startEventDate));

    const handleSeasonChange = (evt) => {
        setCurrentSeason(evt.target.value);
    };

    return (
        <div>
            {matchlistBanner && (
                <Banner
                    image={matchlistBanner?.image}
                    title={matchlistBanner?.title[lang.translationKey]}
                    subtitle={matchlistBanner?.subtitle[lang.translationKey]}
                    link={matchlistBanner?.matchLink?.slug.current}
                />
            )}

            <div className={`${style.matchList__content} wrapper`}>
                <main>
                    <a
                        href="#calendar"
                        className={`${style.matchList__content__scrollDown} cta-white-ter`}
                        aria-label={getFormattedMessage('match.scrollToCalendar', lang)}
                    >
                        <FormattedMessage id="match.scrollToCalendar" />
                    </a>

                    <ul className="tabs tabs--NoScrollDesk">
                        <li>
                            <CustomLink
                                namedRoute="matchList"
                                urlParams={`?selectedTeam=${filteredTeam}`}
                                className={`tab-item ${showResults ? '' : 'active'}`}
                            >
                                <FormattedMessage id="match.upcomingMatches" />
                            </CustomLink>
                        </li>
                        <li>
                            <CustomLink
                                namedRoute="matchResultList"
                                urlParams={`?selectedTeam=${filteredTeam}`}
                                className={`tab-item ${showResults ? 'active' : ''}`}
                            >
                                <FormattedMessage id="match.results" />
                            </CustomLink>
                        </li>
                    </ul>
                    <ul className="tabs tabs--NoScrollDesk">
                        <li key="all">
                            <button
                                onClick={() => setFilteredTeam(null)}
                                className={filteredTeam === null ? 'active' : ''}
                                type="button"
                                aria-label={getFormattedMessage('global.aTeams', lang)}
                            >
                                <FormattedMessage id="global.aTeams" />
                            </button>
                        </li>
                        {club.teams
                            .filter(
                                (item) =>
                                    item.title &&
                                    item.title[lang.translationKey] &&
                                    item.playerpage_visible === true,
                            )
                            .map((item, index) => (
                                <li key={index}>
                                    <button
                                        onClick={() => setFilteredTeam(item.id)}
                                        className={filteredTeam === item.id ? 'active' : ''}
                                        type="button"
                                    >
                                        {item.title[lang.translationKey]}
                                    </button>
                                </li>
                            ))}
                    </ul>
                        {showResults ? (
                            <div className={style.matchList__seasonHeader}>
                                <h2 className="title-xs-mobile title-sm text-semibold">
                                    <FormattedMessage id="match.results" />
                                </h2>
                                <div className={`${statstyle.stat__select} ${style.matchList__seasonHeader__dropdown}`}>

                                    <select name="seasonPicker" id="seasonPicker" onChange={handleSeasonChange}>
                                        {seasons
                                        .filter(
                                            (season) =>
                                                season >= 2022,
                                        )
                                        .map((season, index) => (
                                            <option key={index} value={season}>
                                                {season}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        ) : (
                            <h2 className="title-xs-mobile title-sm text-semibold">
                                <FormattedMessage id="match.matches" />
                            </h2>
                        )}

                    {showResults ? (
                        <ul className={style.matchList__content__list}>
                            {results().length > 0 ? (
                                results()
                                    .map((item, index) => (
                                    <li key={index}>
                                        <MatchItem match={item} isHorizontalFormat />
                                    </li>
                                ))
                            ) : (
                                <li>
                                    <p>
                                        <FormattedMessage id="match.noResults" />
                                    </p>
                                </li>
                            )}
                        </ul>
                    ) : (
                        <ul className={style.matchList__content__list}>
                            {results().length > 0 &&
                                results()
                                    .slice(0, TOP_LIST_LIMIT)
                                    .map((item, index) => (
                                        <li key={index} className="hidden-mobile">
                                            <MatchItem match={item} isHorizontalFormat />
                                        </li>
                                    ))
                            }
                            {upcomingMatches().length > 0 ? (
                                upcomingMatches().map((item, index) => (
                                    <li key={index}>
                                        <MatchItem match={item} isHorizontalFormat />
                                    </li>
                                ))
                            ) : (
                                <li>
                                    <p>
                                        <FormattedMessage id="match.noMatches" />
                                    </p>
                                </li>
                            )}
                        </ul>
                    )}
                </main>

                <aside id="calendar">
                    <Calendar matches={matches} filteredTeam={filteredTeam} />

                    {filteredTeam !== '' && (
                        <ResultsTable teamId={filteredTeam} />
                    )}

                    {matchlistAds && matchlistAds.length !== 0 && (
                        <div className={style.matchList__ads__container}>
                            {matchlistAds.map((item, index) => (
                                <a
                                    key={index}
                                    href={item.url}
                                    target={item.external ? '_blank' : null}
                                    rel="noreferrer"
                                    className={style.matchList__ads__item}
                                >
                                    <img src={item.image.asset.url} alt={item.alt} />
                                </a>
                            ))}
                        </div>
                    )}
                </aside>
            </div>
        </div>
    );
};

export default MatchList;
